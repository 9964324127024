import { createContext } from "react"
export const features = {
  production: {
    buyPage: false,
    sellPage: true,
    browsePage: true,
    myAuctions: true,
    deleteButton: false,
  },
  test: {
    buyPage: false,
    sellPage: true,
    browsePage: true,
    myAuctions: true,
    deleteButton: false,
  },
  dev: {
    buyPage: false,
    sellPage: true,
    browsePage: true,
    myAuctions: true,
    deleteButton: false,
  }
}
export const FeatureFlagContext = createContext(features.dev)